<template lang="pug">
  div.d-flex.flex-column
      HeaderGrid(:name="name" :uid="uid" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" :archiveMode="archiveMode" :archive="archive" @refreshRow="refreshRow" :indexSearch="indexSearch")
      .d-flex.w-100.h-100(style="overflow: hidden;")
        .text-center.flex-center.w-100(v-if='isLoadingCompanyIndicatorsList || isLoadingCompanyIndicatorsListArchived ||  !headerLoaded')
          .loading-bg-inner
              .loader
                .outer
                .middle
                .inner
          .mt-5
              br
              br
              br
              | Chargement de la liste des indicateurs sur les sociétés...
        sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :editSettings='editSettings'  :allowGrouping="allowGrouping" :gridData="dataFiltered" :name="name" :headerData="headerData" @rowClicked="onRowClicked"  @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton" @actionBegin="actionBegin" :archive="archive" :archiveMode="archiveMode" @deleteButtonClicked="deleteButtonClicked" @archiveButtonClicked="archiveButtonClicked" @restoreButtonClicked="restoreButtonClicked")
  </template>

<script>
import { mapGetters, mapActions } from "vuex";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";
import { typeFields } from "@/types/api-orisis/enums/enums";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

export default {
  components: {
    SyncGrid,
    HeaderGrid,
  },
  data() {
    return {
      indexSearch: 0,
      name: "companyIndicator",
      uid: "companyIndicators",
      archive: false,
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      selectedRows: [],

      dateRanger: false,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: false,
      archiveMode: true,
      searchPlaceholder: "Rechercher un indicateur",
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Dialog",
        titleAdd: "Nouvel indicateur",
        titleEdit: "Modifier un indicateur",
        titleField: "label",
      },
      allowGrouping: false,
    };
  },
  beforeMount() {
    this.headerData = [
      {
        type: "checkbox",
        headerText: null,
        width: 30,
        maxWidth: 30,
        visible: false,
      },
      {
        type: "number",
        field: "companyTypeId",
        filterTemplate: "companyTypeFilterTemplate",
        headerText: "Lié au tiers :",
        width: "auto",
        minWidth: 160,
        allowFiltering: true,
        allowSorting: true,
        allowEditing: true,
        showInColumnChooser: true,
        visible: true,
        valueAccessor: "dropdown",
        foreignKeyValue: "label",
        dataSource: this.companiesTypesList,
        edit: {
          source: this.companiesTypesList,
          fields: { text: "label", value: "id" },
          type: "dropdown",
          name: "companyTypeId",
        },
        // editType: "dropdownedit",
      },
      {
        type: "string",
        field: "typeField",
        filterTemplate: "typeFieldFilterTemplate",
        headerText: "Type d'indicateur",
        width: 160,
        minWidth: 160,
        allowFiltering: true,
        allowSorting: true,
        allowEditing: true,
        showInColumnChooser: true,
        visible: true,
        template: "typeFieldTemplate",
        // editType: "dropdownedit",
        valueAccessor: "dropdown",
        defaultValue: 1,
        edit: {
          source: typeFields,
          fields: { text: "label", value: "value" },
          type: "dropdown",
          name: "typeField",
        },
      },
      {
        type: "string",
        field: "label",
        headerText: "Libellé",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        allowEditing: true,
        showInColumnChooser: true,
        visible: true,
        validationRules: { required: true, minLength: 2 },
      },
      {
        type: "string",
        field: "options",
        headerText: "Options (séparées par ';')",
        width: "auto",
        minWidth: 200,
        allowFiltering: false,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        showFooterSum: false,
        edit: { type: "textarea", name: "options" },
      },
    ];
  },
  computed: {
    ...mapGetters([
      "companiesTypesList",
      "companyIndicatorsList",
      "isLoadingCompanyIndicatorsList",
      "companyIndicatorsListArchived",
      "isLoadingCompanyIndicatorsListArchived",
      "workspaceSelected",
    ]),
    dataFiltered() {
      if (this.archive) {
        return this.$store.getters[this.name + "sListArchived"];
      } else {
        return this.$store.getters[this.name + "sList"];
      }
    },
  },
  async created() {
    if (!this.companiesTypesList || this.companiesTypesList.length == 0) {
      this.getCompanyTypes({});
    }
    this.getCompanyIndicators({}).then(async (res) => {
      if (res.length) {
        await this.setColumnsByObject(res[0], null, this);
        this.indexSearch++;
      }
      this.headerLoaded = true;
    });
  },
  methods: {
    ...mapActions([
      "getCompanyTypes",
      "getCompanyIndicators",
      "getCompanyIndicatorsArchived",
      "createCompanyIndicator",
      "updateCompanyIndicator",
      "archiveCompanyIndicators",
      "deleteCompanyIndicators",
      "restoreCompanyIndicators",
    ]),
    formatCurrency,
    setColumnsByObject,
    async actionBegin(args) {
      if (args.requestType === "save" && args.action === "add") {
        await this.createCompanyIndicator({
          companyIndicator: args.data,
        });
        this.$refs.overviewgrid.$refs.overviewgrid.refresh();
      }
      if (args.requestType === "save" && args.action === "edit") {
        await this.updateCompanyIndicator({ companyIndicator: args.data });
        this.$refs.overviewgrid.$refs.overviewgrid.refresh();
      }
    },
    clickAction() {
      this.$refs.overviewgrid.$refs.overviewgrid.addRecord();
    },
    onRowClicked(event) {
      //this.$refs.overviewgrid.selectRow(event.rowIndex, false)
      // this.$refs.overviewgrid.$refs.overviewgrid.startEdit();
    },
    async archiveButtonClicked(args) {
      await this.archiveCompanyIndicators({
        companyIndicatorIds: [args.rowData.id],
      });
      this.$refs.overviewgrid.$refs.overviewgrid.closeEdit();
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    async restoreButtonClicked(args) {
      await this.restoreCompanyIndicators({
        companyIndicatorIds: [args.rowData.id],
      });
      this.$refs.overviewgrid.$refs.overviewgrid.closeEdit();
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    async deleteButtonClicked(args) {
      await this.deleteCompanyIndicators({
        companyIndicatorIds: [args.rowData.id],
      });
      this.$refs.overviewgrid.$refs.overviewgrid.closeEdit();
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else if (args.item.type == "archive") {
        this.getCompanyIndicatorsArchived({});
        this.archive = true;
      } else if (args.item.type == "current") {
        this.getCompanyIndicators({});
        this.archive = false;
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
  },
};
</script>
